* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

:root {
  --primary-color: #659dbd;
  --primary-light-color: #379683;
  --secondary-color: #ff5c60;
  --light-primary-color: #fef1ec;
  --light-color: #f3f3f3;
  --dark-color: #000;
  --max-width: 1100px;
  --border-color: #ddd;
  --white-color: #fff;
  --primary-text-color: #464647;
  --c-color: #ccc;
  --e-color: #eee;
  --text-color: #464647;
}

@font-face {
  font-family: "AvenirNextLTPro-Light";
  src: url("./fonts/AvenirLTStd-Light.otf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "AvenirNextLTPro-Regular";
  src: url("./fonts/AvenirNextLTPro-Regular.otf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "AvenirNextLTPro-Demi";
  src: url("./fonts/AvenirNextLTPro-Demi.otf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "AvenirNextLTPro-Bold";
  src: url("./fonts/AvenirNextLTPro-Demi.otf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "AvenirNextLTPro-Black";
  src: url("./fonts/AvenirLTStd-Black.otf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: "AvenirNextLTPro-Light" !important;
  line-height: 1.5 !important;
  color: var(--text-color) !important;
}

::-webkit-scrollbar {
  width: 1px;
  height: 1px;
}
// ::-webkit-scrollbar-button {
//   background: #000;
// }
// ::-webkit-scrollbar-track-piece {
//   background: #000;
// }
::-webkit-scrollbar-thumb {
  background: #000;
}
​

// utility classes

.primary-color {
  color: var(--primary-color);
}

.font7 {
  font-size: 7pt !important;
}
.font8 {
  font-size: 8pt !important;
}
.font9 {
  font-size: 9pt !important;
}
.font10-42 {
  font-size: 10.42pt !important;
}
.font10 {
  font-size: 10pt !important;
}
.font11 {
  font-size: 11pt !important;
}
.font12 {
  font-size: 12pt !important;
}
.font13 {
  font-size: 13pt !important;
}
.font13-25 {
  font-size: 13.25pt !important;
}
.font14 {
  font-size: 14pt !important;
}
.font15 {
  font-size: 15pt !important;
}
.font16 {
  font-size: 16pt !important;
}
.font18 {
  font-size: 18pt !important;
}
.font20 {
  font-size: 20pt !important;
}
.font22 {
  font-size: 22pt !important;
}
.font24 {
  font-size: 24pt !important;
}
.font26 {
  font-size: 26pt !important;
}
.font30 {
  font-size: 30pt !important;
}
.font32 {
  font-size: 32pt !important;
}
.font33 {
  font-size: 33pt !important;
}
.font39 {
  font-size: 39pt !important;
}
.font40 {
  font-size: 40pt !important;
}
.font42 {
  font-size: 42pt !important;
}
.font47 {
  font-size: 47pt !important;
}
.font48 {
  font-size: 48pt !important;
}

.strike-out-text {
  text-decoration: line-through;
}

.main-container {
  margin-top: 20px;
}

.content-wrapper {
  max-width: 100vw;
  margin: auto;
}

.section-gap {
  height: 50px;
}

ul,
li {
  padding: 0;
  margin: 0;
}

.bold-text {
  font-family: "AvenirNextLTPro-Bold";
}

.description-text {
  line-height: 2;
  text-align: justify;
  // max-height: 200px;
  overflow: hidden;
  // white-space: nowrap;
  text-overflow: ellipsis;
}

.read-more-activities {
  display: flex;
  position: relative;
  // background-color: red;
  height: 20px;
}

.read-more-activities span {
  position: absolute;
  right: 10px;
  bottom: 0px;
  font-weight: bold;
  cursor: pointer;
}

.read-more-activities span:hover {
  cursor: pointer;
}

.splash-title {
  background: -webkit-linear-gradient(
    top,
    var(--primary-color),
    var(--primary-light-color)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  display: flex;
}

.button {
  background-color: var(--secondary-color);
  padding: 4px 10px;
  color: var(--white-color);
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background: -webkit-linear-gradient(
      right,
      var(--primary-color),
      var(--primary-light-color)
    );
  }
}

.general-text {
  line-height: 1.6;
}

.white-text {
  color: #fff;
}

.card {
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  box-shadow: 0 0 6px 2px var(--c-color);
}

// keyframes

@keyframes marquee {
  0% {
    top: 0;
  }
  100% {
    top: -520px;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.maintenance-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  justify-content: center;
  align-items: center;

  .heading {
    margin-bottom: 20px;
  }
}

.header-parent {
  display: flex;
  padding: 12px 10vw;
  justify-content: space-between;
  background: -webkit-linear-gradient(
    left,
    var(--primary-color),
    var(--primary-light-color)
  );
  position: sticky;
  top: 0;
  z-index: 1000;
  align-items: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-bottom: 16px;

  .footer-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    .footer-item-left {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100%;
      margin: 0 6px;

      .footer-logo {
        height: 62px;
        width: 62px;
        border-radius: 50%;
      }

      .footer-title {
        margin-top: 20px;
      }
    }
  }

  .rs-dropdown-item {
    min-width: 100px;
  }

  .logo-conntainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .website-title {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      span {
        color: var(--white-color);
      }
    }
  }

  .logo-conntainer {
    img {
      height: 66px;
      width: auto;
      border-radius: 60px;
    }
  }

  .links-container {
    justify-self: flex-end;
    align-self: center;
    min-width: 30%;
    display: flex;
    justify-content: flex-end;

    .rs-dropdown {
      border: none;
      outline: none;
    }

    .rs-btn {
      background-color: transparent !important;
      color: #fff;
      font-size: initial;
      font-weight: 900;
      outline: none;
      padding: 8px;

      &:hover {
        border: none;
        outline: none;
      }

      &:focus {
        outline: none;
      }
    }

    .rs-btn > .rs-dropdown-toggle-caret {
      margin-left: 4px;
      display: none;
    }
  }
}

.date_selection_dropdown {
  width: 94%;
  margin-left: 4px;
}

.react-slideshow-container {
  button {
    margin: 20px;
  }

  .each-slide {
    .image-container {
      min-height: 70vh;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      align-items: flex-end;

      .caption-container {
        background-color: rgba(0, 0, 0, 0.6);
        padding: 6px 10px;
        // position: absolute;

        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        // font-size: 20px;
        margin: 0 10vw;
        margin-bottom: 50px;
        border-radius: 10px;
      }
    }
  }

  .zoom-each-slide {
    .zoom-image-container {
      min-height: 340px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      align-items: flex-end;
      border-radius: 8px;

      .caption-container {
        background-color: rgba(0, 0, 0, 0.6);
        padding: 10px;
        margin-bottom: 10px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        margin: 2vw;
        border-radius: 10px;
      }
    }
  }
}

.brief-about-us {
  display: grid;
  grid-template-columns: 2fr 3fr;
  column-gap: 50px;
  //   height: 30vh;

  .about-us-img-container {
    height: 100%;
    display: flex;
    align-items: center;
    img {
      width: 100%;
      height: 260px;
      border-radius: 8px;
      transition: all 500ms ease-in-out;

      &:hover {
        transform: scale(1.03);
        transition: all 600ms ease-in-out;
      }
    }
  }

  .about-us-content {
    display: flex;
    text-align: justify;
    align-items: center;
  }
}

.notifications-highlights {
  .notifications-highlights-inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 50px;
  }

  //   gap: 50px;

  .notification-wrapper {
    .notifications-heading {
      display: flex;
      justify-content: center;
    }

    .notifications-panel {
      width: 38vw;
      height: 340px;
      line-height: 20px;
      font-size: 15px;
      overflow: hidden;
      background-color: #eee;
      padding: 20px;
      border-radius: 8px;
      animation: fadein 1s;
      -moz-animation: fadein 1s; /* Firefox */
      -webkit-animation: fadein 1s; /* Safari and Chrome */
      -o-animation: fadein 1s; /* Opera */

      .notification {
        padding: 10px 20px;
        transition: all 300ms ease-in;

        &:hover {
          cursor: pointer;
          transform: scaleX(1.02);
          transition: all 300ms ease-in;
          color: var(--secondary-color);
        }
      }
      //   }
    }
  }

  .highlights-slider {
    width: 38vw;
    margin-left: 4vw;
  }
}

.homepage-main-areas {
  .homepage-main-areas-inner {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 25px;

    .main-area-item {
      background-color: #eee;
      text-align: justify;
      border-radius: 8px;
      position: relative;

      .img-container {
        overflow: hidden;
        height: 200px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        img {
          width: 100%;
          height: 100%;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          transition: all 1s ease-in-out;

          &:hover {
            transform: scale(1.1);
            transition: all 700ms ease-in-out;
            cursor: pointer;
          }
        }
      }
      .main-area-item-title {
        padding: 5px;
      }
      .text-container {
        padding: 15px;
      }

      .read-more {
        position: absolute;
        bottom: 10px;
        right: 10px;
        color: blue;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.image-gallery {
  width: 100%;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: fit-content !important;
  /* width: max-content; */
  min-width: 100%;
}

.footer-parent-container {
  background: -webkit-linear-gradient(
    left,
    var(--primary-color),
    var(--primary-light-color)
  );
  margin-top: 50px;
  .footer-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background: var(--primary-color);
    padding: 1rem;
    font-size: 0.55rem;
    align-items: center;

    .copyright-info {
      color: var(--white-color);
      display: flex;
      justify-self: start;
      margin-left: 2rem;
    }
    .company-info {
      display: flex;
      justify-self: end;
      margin-right: 2rem;
      color: var(--white-color) !important;

      a,
      a:hover {
        text-decoration: none;
        color: var(--white-color) !important;
      }
    }
  }

  .footer-container-inner {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 40px;
    margin: 0 10vw;
    padding: 10px 0;

    .footer-item {
      color: white;
      padding: 20px 0;

      .social-icons {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        span {
          &:nth-child(2) {
            margin: 0 20px;
          }

          &:hover {
            cursor: pointer;
          }
        }
      }

      .footer-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;

        .footer-item-left {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          height: 100%;

          .footer-logo {
            height: 80px;
            width: 80px;
            border-radius: 50%;
          }

          .footer-title {
            margin-top: 20px;
          }
        }
      }

      .link {
        margin: 10px 0;
        &:nth-child(1) {
          margin-top: 0px;
        }
      }

      &:nth-child(1) {
        text-align: justify;
      }

      &:nth-child(2) {
        justify-self: center;
      }
      &:nth-child(3) {
        justify-self: center;
      }
      &:nth-child(4) {
        justify-self: center;
      }
    }
  }
}

//banner styling
.about-skuast-img-container {
  position: relative;
  text-align: center;
  max-height: 40vh;
  height: 36vh;
  .about-banner-img {
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .about-banner-text {
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    color: var(--white-color);
  }
}

.aboutskuast-k-container {
  .aboutskuast-img-container {
    position: relative;
    text-align: center;
    .about-banner-img img {
      width: 100%;
    }
    .about-banner-text {
      position: absolute;
      top: 40%;
      left: 50%;
      color: var(--white-color);
    }
  }
  .about-ncfc-banner-text {
    position: absolute;
    top: 40%;
    left: 40%;
    color: var(--white-color);
  }
  .aboutskuast-k-desc-container {
    padding: 20px 0px;
    // overflow-y: scroll;
    // height: 40vh;
    // width: 100%;

    .aboutskuast-k-links-container span {
      padding-top: 10px;
      color: var(--primary-color);
      display: flex;
    }
    .aboutskuast-k-links-container span:hover {
      color: var(--secondary-color);
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .aboutskuast-k-desc-container span {
    line-height: 1.5;
    text-justify: auto;
  }
}

.tabs-and-data {
  .tabs-container {
    display: flex;
    padding: 20px;
    padding-left: 0px;
    margin-top: 30px;

    .tab {
      display: flex;
      background-color: #ffffff;
      border-radius: 20px;
      // margin: 0px 10px;
      align-items: center;
      justify-content: center;
      padding: 8px 12px;
      &:hover {
        cursor: pointer;
      }

      &.active-tab {
        background-color: var(--secondary-color);
        color: var(--white-color);
      }
    }
  }

  .tabs-data-description {
    line-height: 2;
    padding: 10px;
    padding-bottom: 70px;

    .tab-data-item-with-link {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .dowload-button {
        background-color: var(--secondary-color);
        padding: 4px 10px;
        color: var(--white-color);
        border-radius: 4px;
      }
    }
  }
}

.page-not-found-parent {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.footer-item .link {
  border-radius: 5px;
  padding: 5px;
}
.footer-item .link:hover {
  background-color: var(--primary-light-color);
  cursor: pointer;
}

.feedback-parent {
  display: flex;
  justify-content: center;
  // height: 90vh;

  .feedback-container {
    width: 50vw;
    height: 90%;
    border-radius: 8px;
    box-shadow: 0 0 3px 2px grey;
    padding: 25px;
    padding-top: 2%;
    margin: 20px;
    .feedback-form {
      display: grid;
      color: black;
      grid-template-columns: auto;
      gap: 10px;
      align-items: center;
      .feedback-title {
        font-size: 24px;
        font-family: "Gill Sans", "Gill Sans MT", "Trebuchet MS", sans-serif;
        margin-bottom: 2%;
        display: flex;
        color: grey;
        justify-content: center;
      }
      .feedback-input {
        padding: 10px;
        border-radius: 3px;
        background-color: transparent;
        outline: none;
        height: 44px;
        border-style: hidden;
        box-shadow: 0 0 2px 1px grey;
      }
      .feedback-message {
        height: 100px;
        padding: 10px;
      }
      .btn-class {
        display: flex;
        justify-content: flex-end;
        .feedback-button {
          margin-top: 5%;
          padding: 8px;
          background-color: var(--secondary-color);
          color: white;
          border-radius: 2px;
          width: 180px;
          display: flex;
          justify-content: center;

          &:hover {
            background-color: var(--primary-color);
            border-radius: 5px;
          }
        }
      }
    }
  }
}

.act-list {
  padding: 40px 0;
  .act-list-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px 0px;
    padding: 16px 0px;
    border-bottom: 1px solid var(--c-color);

    &:last-child {
      border-bottom: none;
    }

    .button-container {
      display: flex;
      justify-content: flex-end;

      .act-button {
        margin-left: 0px;
      }
    }
  }
}

.contact-us-container {
  // width: 80vw;
  // height: 38vh;
  box-shadow: 0 0 2px 2px grey;
  border-radius: 6px;
  margin-bottom: 50px;
  position: relative;
  .upper-half {
    display: grid;
    grid-template-columns: 2fr 3fr;
    padding: 30px;
    padding-bottom: 60px;
    gap: 30px;
    // justify-content: center;
    .left-half {
      width: 100%;
      // margin-top: 5%;
      #contact-us {
      }
      #details {
      }
    }
    .right-half {
      display: flex;
      flex-direction: column;
      justify-content: center;
      #address {
        color: #d90429;
      }
      #phone-no {
        a {
          text-decoration: none;
        }
      }
      #email {
      }
    }
  }
  .lower-half {
    background: -webkit-linear-gradient(
      right,
      var(--primary-color),
      var(--primary-light-color),
      var(--primary-color)
    );
    // margin-top: 14vh;
    height: 40px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    position: absolute;
    bottom: 0px;
    width: 100%;
  }
  &:hover {
    box-shadow: 0 0 4px 2px grey;
  }
}

.map-container {
  .google-map-image {
    width: 60vw;
    height: 60vh;
    border: 5px solid white;
    box-shadow: 0 0 2px 2px grey;
    border-radius: 4px;
    margin-bottom: 20px;
    &:hover {
      box-shadow: 0 0 4px 2px grey;
    }
  }
}

.gallery-container {
  // padding: 50px 0px;

  .gallery-tabs {
    display: flex;
    flex: 1;
    width: 100%;
    margin: 16px 0px;
    background-color: #eee;
    color: #000;
    border-radius: 6px;

    .tab {
      width: 50%;
      flex: 1;
      padding: 12px;
      border-radius: 6px;
      display: flex;
      justify-content: center;

      &:hover {
        cursor: pointer;
      }

      &.active {
        background-color: var(--secondary-color);
        color: var(--white-color);
      }
    }
  }

  .videos-container {
    margin: 20px 0px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;

    .video {
      div {
        margin: 0px;
        iframe {
          width: 100%;
        }
      }
    }
  }
}

// ACTIVITIES STYLING
.activities-container {
  padding: 40px 0px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;

  .activity-item {
    border: 1px solid var(--c-color);
    border-radius: 4px;
    .img-container {
      overflow: hidden;
      border-radius: 4px;
      img {
        width: 100%;
        height: 300px;
        transition: all 1s ease-in-out;

        &:hover {
          transform: scale(1.1);
          transition: all 700ms ease-in-out;
          cursor: pointer;
        }
      }
    }

    .date-time {
      display: flex;
      align-items: center;
      padding: 0 20px;
      margin-top: 10px;

      .date {
        margin-left: 10px;
        margin-top: 3px;
      }
    }

    .title {
      padding: 0 20px;
      margin-top: 10px;
    }

    .content {
      padding: 0 20px;
      margin: 10px 0;
    }
  }
}

// OUR TEAM STYLING BEGINS

.single-profile-card {
  height: 340px;
  display: grid;
  grid-template-columns: auto;
  gap: 1px;
  .profile-icon {
    width: 20px;
    height: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .profile-photo {
    width: 120px;
    height: 120px;
    display: block;
    margin: auto;
    border: 4px solid white;
    box-shadow: 0 0 4px 2px var(--c-color);
    border-radius: 50%;
    &:hover {
      box-shadow: 0 0 6px 4px var(--c-color);
    }
  }

  .desg-container {
    display: flex;
    justify-content: center;
    .designation {
      color: var(--primary-color);
      font-size: 16px;
    }
  }

  .name {
    display: flex;
    justify-content: center;
  }
  &:hover {
    box-shadow: 0 0 8px 4px var(--c-color);
  }
}
.profile-card-container {
  .our-team {
    display: flex;
    justify-content: center;
  }
  .about-team {
    display: flex;
    justify-content: center;
    margin: 0 15vw;
    text-align: center;
  }
}
.profile-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  gap: 20px;
  margin: 30px 0;
}
//OUR TEAM STYLING ENDS

// STAKEHOLDERS DATABASE STYLING BEGINS

.farmers-table {
  margin: 20px 5px;
  display: flex;
  justify-content: center;
  table {
    width: 80vw;
    td {
      border: 1px solid var(--c-color);
      text-align: center;
    }
  }
}

// STAKEHOLDERS DATABASE STYLING ENDS

//PUBLICATIONS
.publications-item {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.notification {
  margin: 20px 0;
  padding: 10px 0;
  border-bottom: 1px solid var(--c-color);

  .title {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 6px;
  }
}

// GALLERY  STYLING START

.image-gallery-slides {
  max-height: 70vh;
  .image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: calc(100vh - 80px);
    width: 100%;
  }
}

// PRICE LIST

.price-list-item {
  margin: 30px 0;
  .list-item {
    display: flex;
  }
}

.schemes {
  .scheme-item {
    border-bottom: 1px solid var(--c-color);
    padding-bottom: 20px;
  }
}

.two-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  .tabs-container {
    scrollbar-width: none;
    display: flex;
    background-color: var(--e-color);
    padding: 0px;
    border-radius: 50px;
    .tab {
      background-color: var(--e-color);
      min-width: 100px;
    }
  }
}

.line-depts {
  .list-item {
    padding: 10px 0px;

    .inner-item {
      display: flex;
      align-items: center;

      .serial {
        background-color: var(--secondary-color);
        // padding: 6px;
        color: white;
        border-radius: 20px;
        min-height: 30px;
        min-width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 4px;
        margin-right: 10px;
      }
    }
  }
}

.css-suriyt-MuiTableRow-root {
  // display: grid !important;
  // grid-template-columns: 1fr 2fr 2fr 5fr !important;
  // width: 100%;

  .css-9l8l7c-MuiTableCell-root {
    width: 20px !important;
  }
  .css-1baotf0-MuiTableCell-root:nth-child(2),
  .css-1baotf0-MuiTableCell-root:nth-child(3) {
    width: 90px !important;
  }
}

.stakeholder-description {
  margin: 20px auto;
}

.branch_wrapper {
  margin-bottom: 42px;
  .title_and_number {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    .number {
      content: "";
      background-color: var(--primary-color);
      min-height: 22px;
      min-width: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 22px;
      font-size: 12px;
      color: white;
      margin-right: 10px;
      margin-bottom: 2px;
      font-weight: bold;
    }
    .location {
      font-size: 22px;
    }
  }

  .facilities {
    margin-bottom: 8px;
    text-decoration: dotted;
  }
  .contact_info {
    font-weight: bold;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1460px) {
  .font7 {
    font-size: 6pt !important;
  }
  .font8 {
    font-size: 7pt !important;
  }
  .font9 {
    font-size: 8pt !important;
  }
  .font10-42 {
    font-size: 9.42pt !important;
  }
  .font10 {
    font-size: 9pt !important;
  }
  .font11 {
    font-size: 9pt !important;
  }
  .font12 {
    font-size: 10pt !important;
  }
  .font13 {
    font-size: 10pt !important;
  }
  .font13-25 {
    font-size: 11.25pt !important;
  }
  .font14 {
    font-size: 12pt !important;
  }
  .font15 {
    font-size: 13pt !important;
  }
  .font16 {
    font-size: 14pt !important;
  }
  .font18 {
    font-size: 16pt !important;
  }
  .font20 {
    font-size: 18pt !important;
  }
  .font22 {
    font-size: 20pt !important;
  }
  .font24 {
    font-size: 22pt !important;
  }
  .font26 {
    font-size: 24pt !important;
  }
  .font30 {
    font-size: 28pt !important;
  }
  .font32 {
    font-size: 30pt !important;
  }
  .font33 {
    font-size: 31pt !important;
  }
  .font39 {
    font-size: 36pt !important;
  }
  .font40 {
    font-size: 37pt !important;
  }
  .font42 {
    font-size: 38pt !important;
  }
  .font47 {
    font-size: 44pt !important;
  }
  .font48 {
    font-size: 45pt !important;
  }

  .header-parent {
    flex-direction: row;
    align-items: center;

    .logo-conntainer {
      justify-content: center;
    }

    .links-container {
      .rs-btn {
        font-size: 13px;
      }
    }
    .footer-inner {
      justify-content: center;
      .footer-item-left {
        flex: none;
      }
    }
  }
}

// MEDIA QUERRIES STARTED

@media only screen and (max-width: 900px) {
  .header-parent {
    // grid-template-columns: 1fr;
    flex-direction: column;
    padding: 10px 5vw;
    position: sticky;
    padding-bottom: 16px;

    .logo-conntainer {
      display: flex;
      justify-content: center;
      align-items: center;
      .website-title {
        .line2,
        .line3 {
          display: none;
        }
      }
    }

    .links-container {
      // margin-top: 10px;

      .multi-level-dropdown-container {
        flex-wrap: wrap;
        display: flex;
        justify-content: center;
      }
    }

    .ext-logos {
      display: flex;
      justify-content: center;
      margin-top: 10px;
      display: none;

      .footer-inner {
        width: fit-content;
      }
    }
  }

  .brief-about-us {
    grid-template-columns: 1fr;
    row-gap: 30px;
  }

  .notifications-highlights {
    .notifications-highlights-inner {
      grid-template-columns: 1fr;
      width: 100%;

      .notification-wrapper {
        margin-bottom: 30px;
        .notifications-panel {
          width: 100%;
          height: 340px;
          font-size: 12px;
          padding: 20px;
        }
      }
    }

    .highlights-slider {
      width: 80vw;
      margin-left: 0px;
    }
  }

  .homepage-main-areas {
    .homepage-main-areas-inner {
      grid-template-columns: 1fr;
      row-gap: 30px;
    }
  }

  .footer-parent-container {
    .footer-container-inner {
      grid-template-columns: 1fr;
      row-gap: 10px;
      margin-top: 0px;

      .footer-item {
        color: white;
        padding: 0;
      }

      .footer-item:nth-child(2),
      .footer-item:nth-child(3) {
        justify-self: flex-start;
      }
    }
  }

  .about-skuast-img-container {
    max-height: 18vh;
    height: 18vh;
    .about-banner-img {
      img {
        width: 100%;
        height: inherit;
      }
    }
  }

  .tabs-and-data {
    .tabs-container {
      scrollbar-width: none;
      padding: 0px;
      // padding-left: 10px;
      overflow-x: scroll;
      margin: 20px 0px;
      border-radius: 4px;
      background-color: #eee;
      justify-content: space-between;
      .tab {
        background-color: #eee;
        border-radius: 4px;
        padding: 8px 12px;
        text-align: center;
        font-weight: 400 !important;
        flex: 1;
      }
    }
  }

  .rest-page {
    padding-bottom: 30px;
  }

  .gallery-container {
    .videos-container {
      grid-template-columns: 1fr;
      gap: 30px;
      .video div iframe {
        height: 220px;
      }
    }
  }

  .activities-container {
    grid-template-columns: 1fr;
    .activity-item {
      .img-container {
        img {
          height: 200px;
        }
      }
    }
  }

  .contact-us-container {
    width: 100%;
    height: 100%;

    .upper-half {
      display: grid;
      grid-template-columns: 1fr;
      margin-top: 0%;
      gap: 0px;
      padding: 20px;

      .left-half {
        width: 100%;
        margin-top: 5%;
        #details {
          font-size: 14px;
          color: var(--primary-color);
        }
      }
    }

    .lower-half {
      display: none;
    }
  }

  .map-container {
    .google-map-image {
      width: 100%;
      height: 50vh;
    }
  }

  .profile-grid {
    grid-template-columns: 1fr;
    row-gap: 30px;
  }

  .feedback-parent .feedback-container {
    width: 100%;
    // height: 90%;
  }

  .footer-parent-container {
    .footer-info {
      grid-template-columns: 1fr;
      row-gap: 12px;

      .copyright-info {
        justify-self: center;
        margin-left: 0px;
      }

      .company-info {
        justify-self: center;
        margin-right: 0px;
        text-align: center;
      }
    }
  }

  .image-gallery {
    width: 80vw;
  }
}

.common_kupos_select_box {
  width: 100%;

  .select_box {
    display: flex;
    padding: 0 10px;
    height: 40px;
    margin-top: 5px;
    background-color: #fff;

    &.border {
      border: 1px solid var(--border-color);
      border-radius: 4px;
    }

    .selected_value {
      flex: 1;
      display: flex;
      align-items: center;
    }

    .placeholder {
      flex: 1;
      display: flex;
      align-items: center;
      color: #444;
      font-size: 14px;
      font-weight: bold;
    }

    img {
      width: 16px;
    }
  }
}

.select_options_container {
  max-height: 80vh;
  overflow-y: auto;
  margin-top: 16px;

  .select_option_item {
    padding: 12px 0px;
    border-bottom: 1px dotted #ccc;
    display: flex;
    justify-content: center;
    font-size: 15px;
    font-weight: bold;
  }

  .selected_option {
    color: var(--primary-color);
  }
}
